import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f068a34e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["placeholder"]
const _hoisted_2 = { for: "orderInputFile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_back_button, {
                slot: "start",
                "default-href": "/exim"
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Import shipped orders")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("main", null, [
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate("Shipped orders")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_label, { class: "ion-text-right ion-padding-end" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.file.name), 1)
                  ]),
                  _: 1
                }),
                _createElementVNode("input", {
                  placeholder: _ctx.translate('Select CSV'),
                  onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.parse && _ctx.parse(...args))),
                  ref: "file",
                  class: "ion-hide",
                  type: "file",
                  id: "orderInputFile"
                }, null, 40, _hoisted_1),
                _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.translate("Upload")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_list, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_list_header, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate("Saved mappings")), 1)
                  ]),
                  _: 1
                }),
                _createElementVNode("div", null, [
                  _createVNode(_component_ion_chip, {
                    disabled: !_ctx.content.length,
                    outline: true,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addFieldMapping()))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.addOutline }, null, 8, ["icon"]),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("New mapping")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["disabled"]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fieldMappings('IMPORD') ?? [], (mapping, index) => {
                    return (_openBlock(), _createBlock(_component_ion_chip, {
                      disabled: !_ctx.content.length,
                      key: index,
                      onClick: ($event: any) => (_ctx.mapFields(mapping.value, index)),
                      outline: _ctx.selectedMappingId != index
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(mapping.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["disabled", "onClick", "outline"]))
                  }), 128))
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_list, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_list_header, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate("Select the column for the following information in the uploaded CSV.")), 1)
                  ]),
                  _: 1
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (fieldValues, field) => {
                  return (_openBlock(), _createBlock(_component_ion_item, { key: field }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_select, {
                        label: _ctx.translate(fieldValues.label),
                        interface: "popover",
                        disabled: !_ctx.content.length || !_ctx.fieldMapping[field],
                        placeholder: _ctx.translate('Select'),
                        modelValue: _ctx.fieldMapping[field].value,
                        "onUpdate:modelValue": ($event: any) => ((_ctx.fieldMapping[field].value) = $event)
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fileColumns, (prop, index) => {
                            return (_openBlock(), _createBlock(_component_ion_select_option, { key: index }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(prop), 1)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 2
                      }, 1032, ["label", "disabled", "placeholder", "modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            }),
            _createVNode(_component_ion_button, {
              disabled: !_ctx.content.length,
              color: "medium",
              onClick: _ctx.save,
              expand: "block"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("Save")), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}