import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35edf01b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "product" }
const _hoisted_2 = { class: "product-info" }
const _hoisted_3 = { class: "overline" }
const _hoisted_4 = { class: "product-count" }
const _hoisted_5 = {
  key: 0,
  class: "action border-top"
}
const _hoisted_6 = {
  key: 0,
  class: "pick-all-qty"
}
const _hoisted_7 = { class: "qty-progress" }
const _hoisted_8 = { class: "to-item-history" }
const _hoisted_9 = { class: "qty-ordered" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxpShopifyImg = _resolveComponent("DxpShopifyImg")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ion_item, { lines: "none" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_DxpShopifyImg, {
                    src: _ctx.getProduct(_ctx.item.productId).mainImageUrl
                  }, null, 8, ["src"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                default: _withCtx(() => [
                  _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.secondaryId, _ctx.getProduct(_ctx.item.productId))), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(_ctx.item.productId)) ? _ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(_ctx.item.productId)) : _ctx.item.productName) + " ", 1),
                  _createElementVNode("p", null, _toDisplayString(_ctx.getFeature(_ctx.getProduct(_ctx.item.productId).featureHierarchy, '1/COLOR/')) + " " + _toDisplayString(_ctx.getFeature(_ctx.getProduct(_ctx.item.productId).featureHierarchy, '1/SIZE/')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_4, [
          (!_ctx.item.shipmentId)
            ? (_openBlock(), _createBlock(_component_ion_item, {
                key: 0,
                lines: "none"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_input, {
                    label: _ctx.translate('Qty'),
                    "label-placement": "floating",
                    ref: "pickedQuantity",
                    type: "number",
                    min: "0",
                    modelValue: _ctx.item.pickedQuantity,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.item.pickedQuantity) = $event)),
                    onIonInput: _cache[1] || (_cache[1] = ($event: any) => {_ctx.updatePickedQuantity($event, _ctx.item); _ctx.validatePickedQuantity($event, _ctx.item); _ctx.markPickedQuantityTouched()}),
                    errorText: _ctx.getErrorText(_ctx.item),
                    disabled: _ctx.isForceScanEnabled
                  }, null, 8, ["label", "modelValue", "errorText", "disabled"])
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_ion_item, {
                key: 1,
                lines: "none"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { slot: "end" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.item.pickedQuantity) + " " + _toDisplayString(_ctx.translate('packed')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
        ])
      ]),
      (_ctx.item.orderedQuantity > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (!_ctx.item.shipmentId)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_ion_button, {
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.pickAll(_ctx.item))),
                    slot: "start",
                    size: "small",
                    fill: "outline"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.translate("Pick All")), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_ion_progress_bar, {
                color: _ctx.getProgressBarColor(_ctx.item),
                value: _ctx.getPickedToOrderedFraction(_ctx.item)
              }, null, 8, ["color", "value"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_ion_chip, {
                outline: "",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.shippedHistory(_ctx.item.productId)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.checkmarkDone }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getShippedQuantity(_ctx.item)) + " " + _toDisplayString(_ctx.translate("shipped")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.item.orderedQuantity) + " " + _toDisplayString(_ctx.translate("ordered")), 1)
                ]),
                _: 1
              })
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}