import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_list, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_item, { button: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                slot: "start",
                icon: _ctx.printOutline
              }, null, 8, ["icon"]),
              _createTextVNode(" " + _toDisplayString(_ctx.translate("Shipping label")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, { button: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                slot: "start",
                icon: _ctx.printOutline
              }, null, 8, ["icon"]),
              _createTextVNode(" " + _toDisplayString(_ctx.translate("Customer letter")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, {
            button: "",
            lines: "none"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                slot: "start",
                icon: _ctx.lockOpenOutline
              }, null, 8, ["icon"]),
              _createTextVNode(" " + _toDisplayString(_ctx.translate("Unpack")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}