import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_list, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list_header, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.reason.enumName ? _ctx.reason.enumName : _ctx.reason.enumId), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, {
            button: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openEditRejectionReasonModal()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translate("Edit name and description")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, {
            button: "",
            lines: "none",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.removeRejectionReason()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translate("Remove reason")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}