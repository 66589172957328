import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-530dc197"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "border-top" }
const _hoisted_2 = { class: "border-top" }
const _hoisted_3 = { class: "border-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_menu_button, { slot: "start" }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate('EXIM')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("section", null, [
            _createVNode(_component_ion_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_subtitle, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("Export")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_card_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate('Packed Orders')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_card_content, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate("Export orders that are packed and haven't been shipped yet. Use the exported file to send the packed order details to a carrier where shipping labels and tracking codes will be generated.")), 1)
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_ion_button, {
                    fill: "clear",
                    onClick: _ctx.downloadPackedOrders
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.translate('Export')) + " ", 1),
                      _createVNode(_component_ion_icon, {
                        slot: "end",
                        icon: _ctx.downloadOutline
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_subtitle, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("Import")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_card_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate('Shipped Orders')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_card_content, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate('Import shipped order details from an external system based on tracking codes. Orders that have tracking codes will automatically be shipped at the end of the day.')), 1)
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_ion_button, {
                    fill: "clear",
                    onClick: _ctx.uploadImportOrders
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.translate('Import')) + " ", 1),
                      _createVNode(_component_ion_icon, {
                        slot: "end",
                        icon: _ctx.cloudUploadOutline
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate('Saved mappings')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_ion_button, {
                    fill: "clear",
                    onClick: _ctx.viewSavedMappings
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.translate('View Saved Mappings')) + " ", 1),
                      _createVNode(_component_ion_icon, {
                        slot: "end",
                        icon: _ctx.bookmarkOutline
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ])
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}