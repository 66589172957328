import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_menu = _resolveComponent("ion-menu")!

  return (_openBlock(), _createBlock(_component_ion_menu, {
    side: "end",
    type: "overlay"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Filters")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_select, {
                    label: _ctx.translate('Duration'),
                    interface: "popover",
                    placeholder: _ctx.translate('Select'),
                    value: _ctx.rejectedOrders.query.rejectionPeriodId,
                    onIonChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.applyFilter($event['detail'].value, 'duration')))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rejectionPeriods, (rejectionPeriod) => {
                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                          key: rejectionPeriod.id,
                          value: rejectionPeriod.id
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(rejectionPeriod.description), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["label", "placeholder", "value"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_select, {
                    label: _ctx.translate('Reason'),
                    interface: "popover",
                    placeholder: _ctx.translate('Select'),
                    value: _ctx.rejectedOrders.query.rejectionReasons,
                    multiple: true,
                    onIonChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.applyFilter($event['detail'].value, 'reason')))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rejectReasons, (rejectionReason) => {
                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                          key: rejectionReason.enumId,
                          value: rejectionReason.enumId
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(rejectionReason.description ? rejectionReason.description : rejectionReason.enumId), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["label", "placeholder", "value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}