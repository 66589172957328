import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withKeys as _withKeys, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59573081"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "rejection-summary" }
const _hoisted_2 = { class: "overline" }
const _hoisted_3 = {
  key: 1,
  class: "empty-state"
}
const _hoisted_4 = {
  key: 1,
  class: "empty-state"
}
const _hoisted_5 = { class: "rejection-search" }
const _hoisted_6 = { class: "order-header" }
const _hoisted_7 = { class: "order-primary-info" }
const _hoisted_8 = { class: "order-tags" }
const _hoisted_9 = { class: "order-metadata" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { class: "rejected-order-item" }
const _hoisted_12 = { class: "product-info" }
const _hoisted_13 = { class: "overline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RejectedOrdersFilters = _resolveComponent("RejectedOrdersFilters")!
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_DxpShopifyImg = _resolveComponent("DxpShopifyImg")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_RejectedOrdersFilters, {
        "menu-id": "rejected-orders-filters",
        "content-id": "rejected-orders-filters",
        queryString: _ctx.rejectedOrders.query.queryString
      }, null, 8, ["queryString"]),
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_menu_button, {
                menu: "start",
                slot: "start"
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Rejections")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button, { menu: "rejected-orders-filters" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.filterOutline }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, {
        ref: "contentRef",
        "scroll-events": true,
        onIonScroll: _cache[7] || (_cache[7] = ($event: any) => (_ctx.enableScrolling())),
        id: "rejected-orders-filters"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ion_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_item, { lines: "none" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, { class: "rejection-count" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.rejectionStats.total), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_select, {
                      label: _ctx.translate('Rejections'),
                      interface: "popover",
                      value: _ctx.rejectedOrders.query.rejectionPeriodId,
                      onIonChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateRejectionPeriod($event['detail'].value)))
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rejectionPeriods, (rejectionPeriod) => {
                          return (_openBlock(), _createBlock(_component_ion_select_option, {
                            key: rejectionPeriod.id,
                            value: rejectionPeriod.id
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(rejectionPeriod.description), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["label", "value"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("Most rejected items")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_button, {
                      disabled: !_ctx.getMostRejectedItems().length,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showAllRejectedItemsModal())),
                      fill: "clear"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate('View All')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                }),
                (_ctx.getMostRejectedItems().length)
                  ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getMostRejectedItems(), (item, index) => {
                          return (_openBlock(), _createBlock(_component_ion_item, {
                            key: item.val,
                            lines: _ctx.getMostRejectedItems().length -1 === index ? 'none' : 'inset'
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_DxpShopifyImg, {
                                    src: _ctx.getProduct(item.val).mainImageUrl,
                                    size: "small"
                                  }, null, 8, ["src"])
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.secondaryId, _ctx.getProduct(item.val))), 1),
                                  _createTextVNode(" " + _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.val)) ? _ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.val)) : item.val), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_ion_note, { slot: "end" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.count), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["lines"]))
                        }), 128))
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("p", null, _toDisplayString(_ctx.translate("No data found")), 1)
                    ]))
              ]),
              _: 1
            }),
            _createVNode(_component_ion_card, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_header, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("Most used reasons")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_button, {
                      disabled: !_ctx.getMostUsedReasons().length,
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showAllReasonsModal())),
                      fill: "clear"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate('View All')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                }),
                (_ctx.getMostUsedReasons().length)
                  ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getMostUsedReasons(), (reason, index) => {
                          return (_openBlock(), _createBlock(_component_ion_item, {
                            key: reason.enumId,
                            lines: _ctx.getMostUsedReasons().length -1 === index ? 'none' : 'inset'
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(reason.description) + " ", 1),
                                  _createElementVNode("p", null, _toDisplayString(reason.enumTypeId), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_ion_note, { slot: "end" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(reason.count), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["lines"]))
                        }), 128))
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createElementVNode("p", null, _toDisplayString(_ctx.translate("No data found")), 1)
                    ]))
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_ion_searchbar, {
              class: "searchbar",
              placeholder: _ctx.translate('Search orders'),
              modelValue: _ctx.queryString,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.queryString) = $event)),
              onKeyup: _cache[4] || (_cache[4] = _withKeys(($event: any) => (_ctx.updateQueryString($event.target.value)), ["enter"]))
            }, null, 8, ["placeholder", "modelValue"]),
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.rejectedOrders.total) + " " + _toDisplayString(_ctx.translate("rejections")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_button, {
              disabled: !_ctx.rejectedOrders.list.length,
              expand: "block",
              fill: "outline",
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.downloadRejections())),
              class: "ion-margin-end"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  slot: "end",
                  icon: _ctx.cloudDownloadOutline
                }, null, 8, ["icon"]),
                _createTextVNode(_toDisplayString(_ctx.translate("Download rejections")), 1)
              ]),
              _: 1
            }, 8, ["disabled"])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rejectedOrders.list, (order) => {
            return (_openBlock(), _createBlock(_component_ion_card, {
              class: "order",
              key: order.orderId
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createElementVNode("strong", null, _toDisplayString(order.customerName), 1),
                        _createElementVNode("p", null, _toDisplayString(_ctx.translate("Ordered")) + " " + _toDisplayString(_ctx.formatUtcDate(order.orderDate, 'dd MMMM yyyy t a ZZZZ')), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_ion_chip, { outline: "" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.pricetagOutline }, null, 8, ["icon"]),
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(order.orderId), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(order.shipmentMethod) + " ", 1),
                        (order.reservedDatetime)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.translate("Last brokered")) + " " + _toDisplayString(_ctx.formatUtcDate(order.reservedDatetime, 'dd MMMM yyyy t a ZZZZ')), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _: 2
                    }, 1024)
                  ])
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(order.items, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: item.orderItemSeqId,
                    class: "order-line-item"
                  }, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_DxpShopifyImg, {
                                  src: _ctx.getProduct(item.productId).mainImageUrl,
                                  size: "small"
                                }, null, 8, ["src"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.secondaryId, _ctx.getProduct(item.productId))), 1),
                                _createTextVNode(" " + _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) ? _ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) : item.productName), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _createElementVNode("div", null, [
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.formatUtcDate(item.rejectedAt, 'dd MMMM yyyy t a ZZZZ')) + " ", 1),
                                _createElementVNode("p", null, _toDisplayString(_ctx.translate('rejected time')), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _createElementVNode("div", null, [
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { lines: "none" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.availableToPromise) + " ", 1),
                                _createElementVNode("p", null, _toDisplayString(_ctx.translate('ATP')), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _createElementVNode("div", null, [
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.rejectionReasonDesc) + " ", 1),
                                _createElementVNode("p", null, _toDisplayString(_ctx.translate('rejection reason')), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _createElementVNode("div", null, [
                        _createVNode(_component_ion_chip, { outline: "" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, { icon: _ctx.personCircleOutline }, null, 8, ["icon"]),
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.rejectedBy), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ])
                    ])
                  ]))
                }), 128))
              ]),
              _: 2
            }, 1024))
          }), 128)),
          _withDirectives(_createVNode(_component_ion_infinite_scroll, {
            onIonInfinite: _cache[6] || (_cache[6] = ($event: any) => (_ctx.loadMoreRejectedOrders($event))),
            threshold: "100px",
            ref: "infiniteScrollRef"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_infinite_scroll_content, {
                "loading-spinner": "crescent",
                "loading-text": _ctx.translate('Loading')
              }, null, 8, ["loading-text"])
            ]),
            _: 1
          }, 512), [
            [_vShow, _ctx.isRejectedOrdersScrollable()]
          ])
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }))
}