export const SN_CARRIER = 'carrier'
export const CARRIER_UPDATED = SN_CARRIER + '/CARRIER_UPDATED'
export const CARRIER_CLEARED = SN_CARRIER + '/CARRIER_CLEARED'
export const CARRIER_CURRENT_UPDATED = SN_CARRIER + '/CURRENT_CARRIER_UPDATED'
export const CARRIER_CURRENT_CLEARED = SN_CARRIER + '/CURRENT_CARRIER_CLEARED'
export const SHIPMENT_METHODS_UPDATED = SN_CARRIER + '/SHIPMENT_METHODS_UPDATED'
export const CARRIER_SHIPMENT_METHOD_QUERY_UPDATED = SN_CARRIER + '/CARRIER_SHIPMENT_METHOD_QUERY_UPDATED'
export const CARRIER_PRODUCT_STORE_SHIPMENT_METHODS_UPDATED = SN_CARRIER + '/CARRIER_PRODUCT_STORE_SHIPMENT_METHODS_UPDATED'
export const CARRIER_STORE_SHIPMENT_METHODS_UPDATED = SN_CARRIER + '/STORE_SHIPMENT_METHODS_UPDATED'
export const CARRIER_FACILITY_CARRIERS_UPDATED = SN_CARRIER + '/FACILITY_CARRIERS_UPDATED'


