import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0fbfdd20"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "overline" }
const _hoisted_3 = { class: "tablet" }
const _hoisted_4 = { class: "tablet" }
const _hoisted_5 = {
  key: 1,
  class: "empty-state"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_reorder = _resolveComponent("ion-reorder")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_reorder_group = _resolveComponent("ion-reorder-group")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Rejection reasons")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("main", null, [
            (_ctx.filteredReasons.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_ion_reorder_group, {
                    onIonItemReorder: _cache[0] || (_cache[0] = ($event: any) => (_ctx.doReorder($event))),
                    disabled: false
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredReasons, (reason) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "list-item",
                          key: reason.enumId
                        }, [
                          _createVNode(_component_ion_item, { lines: "none" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createElementVNode("p", _hoisted_2, _toDisplayString(reason.enumId), 1),
                                  _createTextVNode(" " + _toDisplayString(reason.enumName ? reason.enumName : reason.enumId) + " ", 1),
                                  _createElementVNode("p", null, _toDisplayString(reason.description), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024),
                          _createElementVNode("div", _hoisted_3, [
                            _createVNode(_component_ion_chip, {
                              outline: "",
                              onClick: ($event: any) => (_ctx.openVarianceTypeActionsPopover($event, reason))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(reason.enumTypeId), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_icon, { icon: _ctx.caretDownOutline }, null, 8, ["icon"])
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _createElementVNode("div", _hoisted_4, [
                            _createVNode(_component_ion_toggle, {
                              checked: _ctx.fulfillmentRejectReasons[reason.enumId],
                              onClick: _withModifiers(($event: any) => (_ctx.updateFulfillmentRejectReasonAssocStatus($event, reason)), ["prevent"])
                            }, null, 8, ["checked", "onClick"])
                          ]),
                          _createVNode(_component_ion_reorder),
                          _createVNode(_component_ion_button, {
                            fill: "clear",
                            color: "medium",
                            onClick: ($event: any) => (_ctx.openRejectionReasonActionsPopover($event, reason))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                slot: "icon-only",
                                icon: _ctx.ellipsisVerticalOutline
                              }, null, 8, ["icon"])
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.translate("No rejection reasons found.")), 1)
                ]))
          ]),
          _createVNode(_component_ion_fab, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openCreateRejectionReasonModal())),
            vertical: "bottom",
            horizontal: "end",
            slot: "fixed"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_fab_button, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.addOutline }, null, 8, ["icon"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}