import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c91cbd2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "ion-text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_back_button, {
                slot: "start",
                "default-href": "/tabs/orders"
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Notifications")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("main", null, [
            _createElementVNode("section", null, [
              (_ctx.notifications.length)
                ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notification, index) => {
                        return (_openBlock(), _createBlock(_component_ion_item, { key: index }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                              default: _withCtx(() => [
                                _createElementVNode("h3", null, _toDisplayString(notification.data.title), 1),
                                _createElementVNode("p", null, _toDisplayString(notification.data.body), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_note, { slot: "end" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.timeTillNotification(notification.time)), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.translate('No notifications to show')), 1))
            ])
          ]),
          _createVNode(_component_ion_fab, {
            slot: "fixed",
            size: "small",
            vertical: "top",
            horizontal: "end",
            edge: true
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_fab_button, {
                size: "small",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openNotificationSettings()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.cogOutline }, null, 8, ["icon"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}