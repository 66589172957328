import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "empty-state"
}
const _hoisted_2 = {
  key: 1,
  class: "empty-state"
}
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, { onClick: _ctx.closeModal }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "icon-only",
                      icon: _ctx.close
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("Edit pickers")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, { class: "ion-padding" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_searchbar, {
          modelValue: _ctx.queryString,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.queryString) = $event)),
          onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => {_ctx.queryString = $event.target.value; _ctx.findPickers()}, ["enter"]))
        }, null, 8, ["modelValue"]),
        _createVNode(_component_ion_row, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedPickers, (picker) => {
              return (_openBlock(), _createBlock(_component_ion_chip, {
                key: picker.id
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(picker.name), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_ion_icon, {
                    icon: _ctx.closeCircle,
                    onClick: ($event: any) => (_ctx.updateSelectedPickers(picker.id))
                  }, null, 8, ["icon", "onClick"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }),
        _createVNode(_component_ion_list, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_list_header, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("Staff")), 1)
              ]),
              _: 1
            }),
            (_ctx.isLoading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_ion_spinner, { name: "crescent" }),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.translate("Fetching pickers")), 1)
                    ]),
                    _: 1
                  })
                ]))
              : (!_ctx.pickers.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString('No picker found')))
                : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pickers, (picker, index) => {
                      return (_openBlock(), _createBlock(_component_ion_item, {
                        key: index,
                        onClick: ($event: any) => (_ctx.updateSelectedPickers(picker.id))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_checkbox, {
                            checked: _ctx.isPickerSelected(picker.id)
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(picker.name) + " ", 1),
                                  _createElementVNode("p", null, _toDisplayString(picker.externalId ? picker.externalId : picker.id), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1032, ["checked"])
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    }), 128))
                  ]))
          ]),
          _: 1
        }),
        _createVNode(_component_ion_fab, {
          vertical: "bottom",
          horizontal: "end",
          slot: "fixed"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_fab_button, {
              disabled: _ctx.arePickersNotSelected(),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.confirmSave()))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, { icon: _ctx.saveOutline }, null, 8, ["icon"])
              ]),
              _: 1
            }, 8, ["disabled"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}