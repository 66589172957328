import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_menu = _resolveComponent("ion-menu")!

  return (_openBlock(), _createBlock(_component_ion_menu, {
    side: "end",
    type: "overlay"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Filters")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, _toDisplayString(_ctx.translate("Shipping Method")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shipmentMethods, (shipmentMethod, index) => {
                return (_openBlock(), _createBlock(_component_ion_item, {
                  button: "",
                  key: index,
                  onClick: ($event: any) => (_ctx.applyFilter(shipmentMethod.val, 'shipment-method'))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_checkbox, {
                      "label-placement": "start",
                      checked: _ctx.transferOrders.query.selectedShipmentMethods.includes(shipmentMethod.val)
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate(_ctx.getShipmentMethodDesc(shipmentMethod.val))), 1)
                      ]),
                      _: 2
                    }, 1032, ["checked"])
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128)),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                    default: _withCtx(() => [
                      _createElementVNode("p", null, _toDisplayString(_ctx.translate("Status")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.statuses, (status, index) => {
                return (_openBlock(), _createBlock(_component_ion_item, {
                  button: "",
                  key: index,
                  onClick: ($event: any) => (_ctx.applyFilter(status.val, 'status'))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_checkbox, {
                      "label-placement": "start",
                      checked: _ctx.transferOrders.query.selectedStatuses.includes(status.val)
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate(_ctx.getStatusDesc(status.val))), 1)
                      ]),
                      _: 2
                    }, 1032, ["checked"])
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}