import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_list, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list_header, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.translate("Variance type")), 1)
            ]),
            _: 1
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rejectReasonEnumTypes, (type) => {
            return (_openBlock(), _createBlock(_component_ion_item, {
              lines: "none",
              button: "",
              onClick: ($event: any) => (_ctx.updateVarianceType(type)),
              key: type.enumTypeId
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(type.enumTypeId), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}