import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d29d12a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tablet" }
const _hoisted_2 = { class: "tablet" }
const _hoisted_3 = { class: "tablet" }
const _hoisted_4 = {
  key: 1,
  class: "empty-state"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_ctx.filteredShipmentMethods?.length > 0)
    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.filteredShipmentMethods, (shipmentMethod) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "list-item ion-padding",
          key: shipmentMethod.shipmentMethodTypeId
        }, [
          _createVNode(_component_ion_item, { lines: "none" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(shipmentMethod.description) + " ", 1),
                  _createElementVNode("p", null, _toDisplayString(shipmentMethod.shipmentMethodTypeId), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1024),
          _createElementVNode("div", _hoisted_1, [
            (shipmentMethod.deliveryDays)
              ? (_openBlock(), _createBlock(_component_ion_chip, {
                  key: 0,
                  outline: "",
                  onClick: _withModifiers(($event: any) => (_ctx.editDeliveryDays(shipmentMethod)), ["stop"])
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(shipmentMethod?.deliveryDays), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              : (_openBlock(), _createBlock(_component_ion_chip, {
                  key: 1,
                  disabled: !shipmentMethod.isChecked,
                  outline: "",
                  onClick: _withModifiers(($event: any) => (_ctx.editDeliveryDays(shipmentMethod)), ["stop"])
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.addCircleOutline }, null, 8, ["icon"]),
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate('delivery days')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1032, ["disabled", "onClick"])),
            _createVNode(_component_ion_note, { class: "config-label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate('delivery days')), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_2, [
            (shipmentMethod?.carrierServiceCode)
              ? (_openBlock(), _createBlock(_component_ion_chip, {
                  key: 0,
                  outline: "",
                  onClick: _withModifiers(($event: any) => (_ctx.editCarrierCode(shipmentMethod)), ["stop"])
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(shipmentMethod?.carrierServiceCode), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              : (_openBlock(), _createBlock(_component_ion_chip, {
                  key: 1,
                  disabled: !shipmentMethod.isChecked,
                  outline: "",
                  onClick: _withModifiers(($event: any) => (_ctx.editCarrierCode(shipmentMethod)), ["stop"])
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.addCircleOutline }, null, 8, ["icon"]),
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate('carrier code')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1032, ["disabled", "onClick"])),
            _createVNode(_component_ion_note, { class: "config-label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate('carrier code')), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_ion_checkbox, {
              checked: shipmentMethod.isChecked,
              onClick: ($event: any) => (_ctx.updateCarrierShipmentMethodAssociation($event, shipmentMethod))
            }, null, 8, ["checked", "onClick"])
          ]),
          _createVNode(_component_ion_button, {
            fill: "clear",
            color: "medium",
            onClick: ($event: any) => (_ctx.openShipmentMethodActionsPopover($event, shipmentMethod))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                slot: "icon-only",
                icon: _ctx.ellipsisVerticalOutline
              }, null, 8, ["icon"])
            ]),
            _: 2
          }, 1032, ["onClick"])
        ]))
      }), 128))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
        _createElementVNode("p", null, _toDisplayString(_ctx.translate('No data found')), 1)
      ]))
}