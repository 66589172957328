import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-74109d32"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "scanner" }
const _hoisted_2 = { slot: "end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_TransferOrderItem = _resolveComponent("TransferOrderItem")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_back_button, {
                "default-href": `/transfer-order-details/${_ctx.currentShipment.primaryOrderId}`,
                slot: "start"
              }, null, 8, ["default-href"]),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Review Shipment")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  (!_ctx.currentShipment.trackingCode && _ctx.showLabelError)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 0,
                        fill: "clear",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.transferShipmentActionsPopover($event)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: _ctx.documentTextOutline }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("main", null, [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_ion_searchbar, {
                class: "searchbar",
                value: _ctx.queryString,
                onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.searchItems($event.target.value)), ["enter"]))
              }, null, 8, ["value"]),
              _createElementVNode("div", null, [
                _createVNode(_component_ion_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.currentShipment.totalQuantityPicked) + " " + _toDisplayString(_ctx.translate("items picked")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_button, {
                      expand: "block",
                      fill: "outline",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.generateShippingLabel(_ctx.currentShipment)))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          slot: "start",
                          icon: _ctx.documentTextOutline
                        }, null, 8, ["icon"]),
                        _createTextVNode(_toDisplayString(_ctx.currentShipment.trackingCode ? _ctx.translate("Regenerate Shipping Label") : _ctx.translate("Generate shipping label")) + " ", 1),
                        (_ctx.currentShipment.isGeneratingShippingLabel)
                          ? (_openBlock(), _createBlock(_component_ion_spinner, {
                              key: 0,
                              color: "primary",
                              slot: "start",
                              name: "crescent"
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_item, null, {
                  default: _withCtx(() => [
                    (!_ctx.currentShipment.trackingCode)
                      ? (_openBlock(), _createBlock(_component_ion_input, {
                          key: 0,
                          label: _ctx.translate('Tracking Code'),
                          placeholder: "add tracking code",
                          modelValue: _ctx.trackingCode,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.trackingCode) = $event))
                        }, null, 8, ["label", "modelValue"]))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("Tracking Code")), 1)
                            ]),
                            _: 1
                          }),
                          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.currentShipment.trackingCode), 1)
                        ], 64))
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate('Carrier')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_label, { slot: "end" }, {
                      default: _withCtx(() => [
                        _createElementVNode("p", null, _toDisplayString(_ctx.getPartyName(_ctx.currentShipment.carrierPartyId)) + " " + _toDisplayString(_ctx.getShipmentMethodDesc(_ctx.currentShipment.shipmentMethodTypeId)), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ])
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shipmentItems, (item) => {
              return (_openBlock(), _createBlock(_component_TransferOrderItem, {
                key: item.shipmentItemSeqId,
                itemDetail: item
              }, null, 8, ["itemDetail"]))
            }), 128))
          ]),
          _createVNode(_component_ion_fab, {
            vertical: "bottom",
            horizontal: "end",
            slot: "fixed"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_fab_button, {
                disabled: !_ctx.hasPermission(_ctx.Actions.APP_TRANSFER_ORDER_UPDATE) || !Object.keys(_ctx.currentShipment).length || (_ctx.currentShipment.isTrackingRequired &&  !_ctx.trackingCode?.trim()),
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.confirmShip()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.sendOutline }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}