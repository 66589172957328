import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, { onClick: _ctx.closeModal }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("CSV Mapping")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_item, { lines: "full" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_input, {
          label: _ctx.translate('Mapping name'),
          placeholder: _ctx.translate('Field mapping name'),
          modelValue: _ctx.mappingName,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.mappingName) = $event))
        }, null, 8, ["label", "placeholder", "modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, { class: "ion-padding" }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_ion_list, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fieldMapping, (fieldValues, field) => {
                return (_openBlock(), _createBlock(_component_ion_item, { key: field }, {
                  default: _withCtx(() => [
                    (_ctx.mappingType === 'EXPORD')
                      ? (_openBlock(), _createBlock(_component_ion_input, {
                          key: 0,
                          "label-placement": "fixed",
                          label: _ctx.translate(fieldValues.label),
                          modelValue: fieldValues.value,
                          "onUpdate:modelValue": ($event: any) => ((fieldValues.value) = $event)
                        }, null, 8, ["label", "modelValue", "onUpdate:modelValue"]))
                      : (_openBlock(), _createBlock(_component_ion_select, {
                          key: 1,
                          label: _ctx.translate(fieldValues.label),
                          interface: "popover",
                          placeholder: _ctx.translate('Select'),
                          modelValue: fieldValues.value,
                          "onUpdate:modelValue": ($event: any) => ((fieldValues.value) = $event)
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fileColumns, (prop, index) => {
                              return (_openBlock(), _createBlock(_component_ion_select_option, { key: index }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(prop), 1)
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          ]),
                          _: 2
                        }, 1032, ["label", "placeholder", "modelValue", "onUpdate:modelValue"]))
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_ion_fab, {
          vertical: "bottom",
          horizontal: "end",
          slot: "fixed"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_fab_button, { onClick: _ctx.saveMapping }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, { icon: _ctx.saveOutline }, null, 8, ["icon"])
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}