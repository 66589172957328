import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-030a7d9a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "filters" }
const _hoisted_3 = { class: "results" }
const _hoisted_4 = { class: "order-header" }
const _hoisted_5 = { class: "order-primary-info" }
const _hoisted_6 = { class: "order-tags" }
const _hoisted_7 = { class: "order-metadata" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "order-item" }
const _hoisted_10 = { class: "product-info" }
const _hoisted_11 = { class: "overline" }
const _hoisted_12 = { class: "product-metadata" }
const _hoisted_13 = {
  key: 0,
  class: "kit-components"
}
const _hoisted_14 = {
  key: 1,
  class: "kit-components"
}
const _hoisted_15 = { class: "overline" }
const _hoisted_16 = {
  key: 2,
  class: "empty-state"
}
const _hoisted_17 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ViewSizeSelector = _resolveComponent("ViewSizeSelector")!
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_DxpShopifyImg = _resolveComponent("DxpShopifyImg")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ViewSizeSelector, {
        "menu-id": "view-size-selector-open",
        "content-id": "view-size-selector"
      }),
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_menu_button, {
                menu: "start",
                slot: "start"
              }),
              (!_ctx.openOrders.total)
                ? (_openBlock(), _createBlock(_component_ion_title, { key: 0 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.openOrders.total) + " " + _toDisplayString(_ctx.translate('orders')), 1)
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_ion_title, { key: 1 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.openOrders.query.viewSize) + " " + _toDisplayString(_ctx.translate('of')) + " " + _toDisplayString(_ctx.openOrders.total) + " " + _toDisplayString(_ctx.translate('orders')), 1)
                    ]),
                    _: 1
                  })),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.viewNotifications()))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "icon-only",
                        icon: _ctx.notificationsOutline,
                        color: (_ctx.unreadNotificationsStatus && _ctx.notifications.length) ? 'primary' : ''
                      }, null, 8, ["icon", "color"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_button, {
                    disabled: !_ctx.hasPermission(_ctx.Actions.APP_RECYCLE_ORDER) || !_ctx.openOrders.total || _ctx.isRejecting,
                    fill: "clear",
                    color: "danger",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.recycleOutstandingOrders()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.translate("Reject all")), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled"]),
                  _createVNode(_component_ion_menu_button, {
                    menu: "view-size-selector-open",
                    disabled: !_ctx.openOrders.total
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.optionsOutline }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, {
        ref: "contentRef",
        "scroll-events": true,
        onIonScroll: _cache[4] || (_cache[4] = ($event: any) => (_ctx.enableScrolling())),
        id: "view-size-selector"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_searchbar, {
            class: "searchbar",
            value: _ctx.openOrders.query.queryString,
            placeholder: _ctx.translate('Search orders'),
            onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.updateQueryString($event.target.value)), ["enter"]))
          }, null, 8, ["value", "placeholder"]),
          (_ctx.openOrders.total)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shipmentMethods, (method) => {
                    return (_openBlock(), _createBlock(_component_ion_item, {
                      lines: "none",
                      key: method.val
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_checkbox, {
                          "label-placement": "end",
                          onIonChange: ($event: any) => (_ctx.updateSelectedShipmentMethods(method.val))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.getShipmentMethodDesc(method.val)) + " ", 1),
                                _createElementVNode("p", null, _toDisplayString(method.ordersCount) + " " + _toDisplayString(_ctx.translate("orders")) + ", " + _toDisplayString(method.count) + " " + _toDisplayString(_ctx.translate("items")), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["onIonChange"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_ion_button, {
                    class: "bulk-action desktop-only",
                    size: "large",
                    onClick: _ctx.assignPickers
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.translate("Print Picklist")), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getOpenOrders(), (order, index) => {
                    return (_openBlock(), _createBlock(_component_ion_card, {
                      class: "order",
                      key: index
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("div", _hoisted_5, [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createElementVNode("strong", null, _toDisplayString(order.customerName), 1),
                                _createElementVNode("p", null, _toDisplayString(_ctx.translate("Ordered")) + " " + _toDisplayString(_ctx.formatUtcDate(order.orderDate, 'dd MMMM yyyy t a ZZZZ')), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _createElementVNode("div", _hoisted_6, [
                            _createVNode(_component_ion_chip, {
                              onClick: _withModifiers(($event: any) => (_ctx.orderActionsPopover(order, $event)), ["stop"]),
                              outline: ""
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: _ctx.pricetagOutline }, null, 8, ["icon"]),
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(order.orderName), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_icon, { icon: _ctx.caretDownOutline }, null, 8, ["icon"])
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _createElementVNode("div", _hoisted_7, [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(order.shipmentMethodTypeDesc) + " ", 1),
                                (order.reservedDatetime)
                                  ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.translate("Last brokered")) + " " + _toDisplayString(_ctx.formatUtcDate(order.reservedDatetime, 'dd MMMM yyyy t a ZZZZ')), 1))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024)
                          ])
                        ]),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(order.items, (item) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: item.orderItemSeqId,
                            class: "order-line-item"
                          }, [
                            _createElementVNode("div", _hoisted_9, [
                              _createElementVNode("div", _hoisted_10, [
                                _createVNode(_component_ion_item, { lines: "none" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_DxpShopifyImg, {
                                          src: _ctx.getProduct(item.productId).mainImageUrl,
                                          size: "small"
                                        }, null, 8, ["src"])
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => [
                                        _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.secondaryId, _ctx.getProduct(item.productId))), 1),
                                        _createTextVNode(" " + _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) ? _ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) : _ctx.getProduct(item.productId).productName) + " ", 1),
                                        (_ctx.isKit(item))
                                          ? (_openBlock(), _createBlock(_component_ion_badge, {
                                              key: 0,
                                              color: "dark"
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.translate("Kit")), 1)
                                              ]),
                                              _: 1
                                            }))
                                          : _createCommentVNode("", true),
                                        _createElementVNode("p", null, _toDisplayString(_ctx.getFeature(_ctx.getProduct(item.productId).featureHierarchy, '1/COLOR/')) + " " + _toDisplayString(_ctx.getFeature(_ctx.getProduct(item.productId).featureHierarchy, '1/SIZE/')), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _createElementVNode("div", _hoisted_12, [
                                (_ctx.isKit(item))
                                  ? (_openBlock(), _createBlock(_component_ion_button, {
                                      key: 0,
                                      fill: "clear",
                                      size: "small",
                                      onClick: _withModifiers(($event: any) => (_ctx.fetchKitComponents(item)), ["stop"])
                                    }, {
                                      default: _withCtx(() => [
                                        (item.showKitComponents)
                                          ? (_openBlock(), _createBlock(_component_ion_icon, {
                                              key: 0,
                                              color: "medium",
                                              slot: "icon-only",
                                              icon: _ctx.chevronUpOutline
                                            }, null, 8, ["icon"]))
                                          : (_openBlock(), _createBlock(_component_ion_icon, {
                                              key: 1,
                                              color: "medium",
                                              slot: "icon-only",
                                              icon: _ctx.listOutline
                                            }, null, 8, ["icon"]))
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]))
                                  : _createCommentVNode("", true),
                                (_ctx.getProductStock(item.productId).quantityOnHandTotal)
                                  ? (_openBlock(), _createBlock(_component_ion_note, { key: 1 }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.getProductStock(item.productId).quantityOnHandTotal) + " " + _toDisplayString(_ctx.translate('pieces in stock')), 1)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : (_openBlock(), _createBlock(_component_ion_button, {
                                      key: 2,
                                      fill: "clear",
                                      size: "small",
                                      onClick: _withModifiers(($event: any) => (_ctx.fetchProductStock(item.productId)), ["stop"])
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_icon, {
                                          color: "medium",
                                          slot: "icon-only",
                                          icon: _ctx.cubeOutline
                                        }, null, 8, ["icon"])
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]))
                              ])
                            ]),
                            (item.showKitComponents && !_ctx.getProduct(item.productId)?.productComponents)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                  _createVNode(_component_ion_item, { lines: "none" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_skeleton_text, {
                                        animated: "",
                                        style: {"height":"80%"}
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_item, { lines: "none" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_skeleton_text, {
                                        animated: "",
                                        style: {"height":"80%"}
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]))
                              : (item.showKitComponents && _ctx.getProduct(item.productId)?.productComponents)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getProduct(item.productId).productComponents, (productComponent, index) => {
                                      return (_openBlock(), _createBlock(_component_ion_card, { key: index }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_item, { lines: "none" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_DxpShopifyImg, {
                                                    src: _ctx.getProduct(productComponent.productIdTo).mainImageUrl,
                                                    size: "small"
                                                  }, null, 8, ["src"])
                                                ]),
                                                _: 2
                                              }, 1024),
                                              _createVNode(_component_ion_label, null, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.secondaryId, _ctx.getProduct(productComponent.productIdTo))), 1),
                                                  _createTextVNode(" " + _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(productComponent.productIdTo)) ? _ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(productComponent.productIdTo)) : productComponent.productIdTo) + " ", 1),
                                                  _createElementVNode("p", null, _toDisplayString(_ctx.getFeature(_ctx.getProduct(productComponent.productIdTo).featureHierarchy, '1/COLOR/')) + " " + _toDisplayString(_ctx.getFeature(_ctx.getProduct(productComponent.productIdTo).featureHierarchy, '1/SIZE/')), 1)
                                                ]),
                                                _: 2
                                              }, 1024)
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]),
                                        _: 2
                                      }, 1024))
                                    }), 128))
                                  ]))
                                : _createCommentVNode("", true)
                          ]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1024))
                  }), 128)),
                  _withDirectives(_createVNode(_component_ion_infinite_scroll, {
                    onIonInfinite: _cache[3] || (_cache[3] = ($event: any) => (_ctx.loadMoreOpenOrders($event))),
                    threshold: "100px",
                    ref: "infiniteScrollRef"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_infinite_scroll_content, {
                        "loading-spinner": "crescent",
                        "loading-text": _ctx.translate('Loading')
                      }, null, 8, ["loading-text"])
                    ]),
                    _: 1
                  }, 512), [
                    [_vShow, _ctx.isOpenOrdersScrollable()]
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.openOrders.total)
            ? (_openBlock(), _createBlock(_component_ion_fab, {
                key: 1,
                class: "mobile-only",
                vertical: "bottom",
                horizontal: "end",
                slot: "fixed"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_fab_button, { onClick: _ctx.assignPickers }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.printOutline }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }))
            : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                _createElementVNode("p", {
                  innerHTML: _ctx.getErrorMessage()
                }, null, 8, _hoisted_17)
              ]))
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }))
}