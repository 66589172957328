import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "empty-state"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "icon-only",
                      icon: _ctx.closeOutline
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("Gift card activation")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        (_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_ion_spinner, { name: "crescent" }),
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Fetching gift card info.")), 1)
                ]),
                _: 1
              })
            ]))
          : (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
              default: _withCtx(() => [
                (!_ctx.item.isGCActivated)
                  ? (_openBlock(), _createBlock(_component_ion_item, {
                      key: 0,
                      lines: "none"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_input, {
                          label: _ctx.translate('Activation code'),
                          placeholder: _ctx.translate('serial number'),
                          "helper-text": _ctx.translate('Scan or enter the unique code on the gift card'),
                          modelValue: _ctx.activationCode,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activationCode) = $event))
                        }, null, 8, ["label", "placeholder", "helper-text", "modelValue"])
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_ion_item, { key: 1 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, {
                          icon: _ctx.cardOutline,
                          slot: "start"
                        }, null, 8, ["icon"]),
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.item.gcInfo.cardNumber), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_note, { slot: "end" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.getCreatedDateTime()), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })),
                _createVNode(_component_ion_item, { lines: "none" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      icon: _ctx.giftOutline,
                      slot: "start"
                    }, null, 8, ["icon"]),
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(_ctx.item.productId)) ? _ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(_ctx.item.productId)) : _ctx.item.productName) + " ", 1),
                        _createElementVNode("p", null, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.secondaryId, _ctx.getProduct(_ctx.item.productId))), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_label, { slot: "end" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.formatCurrency(_ctx.itemPriceInfo.unitPrice, _ctx.itemPriceInfo.currencyUom)), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
      ]),
      _: 1
    }),
    (!_ctx.item.isGCActivated)
      ? (_openBlock(), _createBlock(_component_ion_fab, {
          key: 0,
          vertical: "bottom",
          horizontal: "end",
          slot: "fixed"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_fab_button, {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.confirmSave()))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, { icon: _ctx.cardOutline }, null, 8, ["icon"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}