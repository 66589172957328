import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "results" }
const _hoisted_3 = { class: "overline" }
const _hoisted_4 = {
  key: 1,
  class: "empty-state"
}
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TransferOrderFilters = _resolveComponent("TransferOrderFilters")!
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_TransferOrderFilters, {
        "menu-id": "transfer-order-filters",
        "content-id": "transfer-order-filters",
        queryString: _ctx.transferOrders.query.queryString
      }, null, 8, ["queryString"]),
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_menu_button, {
                menu: "start",
                slot: "start"
              }),
              (!_ctx.transferOrders.total)
                ? (_openBlock(), _createBlock(_component_ion_title, { key: 0 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.transferOrders.total) + " " + _toDisplayString(_ctx.translate('orders')), 1)
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_ion_title, { key: 1 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.transferOrders.list.length) + " " + _toDisplayString(_ctx.translate('of')) + " " + _toDisplayString(_ctx.transferOrders.total) + " " + _toDisplayString(_ctx.translate('orders')), 1)
                    ]),
                    _: 1
                  })),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button, { menu: "transfer-order-filters" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.optionsOutline }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, {
        ref: "contentRef",
        "scroll-events": true,
        onIonScroll: _cache[2] || (_cache[2] = ($event: any) => (_ctx.enableScrolling())),
        id: "transfer-order-filters"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_searchbar, {
            class: "searchbar",
            value: _ctx.transferOrders.query.queryString,
            onKeyup: _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.updateQueryString($event.target.value)), ["enter"]))
          }, null, 8, ["value"]),
          (_ctx.transferOrders.total)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_ion_list, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.transferOrders.list, (order, index) => {
                        return (_openBlock(), _createBlock(_component_ion_item, {
                          key: index,
                          onClick: ($event: any) => (_ctx.viewTransferOrderDetail(order)),
                          button: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createElementVNode("p", _hoisted_3, _toDisplayString(order.orderId), 1),
                                _createTextVNode(" " + _toDisplayString(order.orderName) + " ", 1),
                                _createElementVNode("p", null, _toDisplayString(order.externalId), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_badge, { slot: "end" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(order.orderStatusDesc), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      }), 128))
                    ]),
                    _: 1
                  }),
                  _withDirectives(_createVNode(_component_ion_infinite_scroll, {
                    onIonInfinite: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loadMoreTransferOrders($event))),
                    threshold: "100px",
                    ref: "infiniteScrollRef"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_infinite_scroll_content, {
                        "loading-spinner": "crescent",
                        "loading-text": _ctx.translate('Loading')
                      }, null, 8, ["loading-text"])
                    ]),
                    _: 1
                  }, 512), [
                    [_vShow, _ctx.isTransferOrdersScrollable()]
                  ])
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("p", {
                  innerHTML: _ctx.getErrorMessage()
                }, null, 8, _hoisted_5)
              ]))
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }))
}