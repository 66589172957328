export default {
    "APP_UPDT_STR_FULFLMNT_CONFIG": "APP_UPDT_STR_FULFLMNT_CONFIG",
    "APP_UPDT_ECOM_INV_CONFIG": "APP_UPDT_ECOM_INV_CONFIG",
    "APP_UNPACK_ORDER": "APP_UNPACK_ORDER",
    "APP_RECYCLE_ORDER": "APP_RECYCLE_ORDER",
    "APP_FORCE_SHIP_ORDER": "APP_FORCE_SHIP_ORDER",
    "APP_TRANSFER_ORDERS_VIEW": "APP_TRANSFER_ORDERS_VIEW",
    "APP_TRANSFER_ORDER_DETAIL_VIEW": "APP_TRANSFER_ORDER_DETAIL_VIEW",
    "APP_TRANSFER_ORDER_UPDATE":"APP_TRANSFER_ORDER_UPDATE",
    "APP_CARRIERS_VIEW" : "APP_CARRIERS_VIEW",
    "APP_CARRIERS_CREATE" : "APP_CARRIERS_CREATE",
    "APP_ORDER_LOOKUP_VIEW": "APP_ORDER_LOOKUP_VIEW",
    "APP_NEW_REJECTION_API_CONFIG_UPDATE": "APP_NEW_REJECTION_API_CONFIG_UPDATE",
    "APP_PARTIAL_ORDER_REJECTION_CONFIG_UPDATE": "APP_PARTIAL_ORDER_REJECTION_CONFIG_UPDATE",
    "APP_COLLATERAL_REJECTION_CONFIG_UPDATE": "APP_COLLATERAL_REJECTION_CONFIG_UPDATE",
    "APP_UPDT_FULFILL_FORCE_SCAN_CONFIG": "APP_UPDT_FULFILL_FORCE_SCAN_CONFIG",
    "APP_ORGANIZATION_HEADER_VIEW": "APP_ORGANIZATION_HEADER_VIEW",
    "APP_REJECTIONS_VIEW": "APP_REJECTIONS_VIEW",
    "APP_INVOICING_STATUS_VIEW": "APP_INVOICING_STATUS_VIEW",
    "APP_PRODUCT_IDENTIFIER_UPDATE": "APP_PRODUCT_IDENTIFIER_UPDATE",
    "APP_COMMERCE_VIEW": "APP_COMMERCE_VIEW"
}