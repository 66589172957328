import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78d8e202"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "results"
}
const _hoisted_2 = { class: "overline" }
const _hoisted_3 = {
  key: 1,
  class: "empty-state"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate('Carriers')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          (_ctx.carriers.total)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_ion_list, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.carriers.list, (carrier, index) => {
                      return (_openBlock(), _createBlock(_component_ion_item, {
                        key: index,
                        onClick: ($event: any) => (_ctx.viewCarrierDetail(carrier)),
                        button: "",
                        detail: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createElementVNode("p", _hoisted_2, _toDisplayString(carrier.partyId), 1),
                              _createTextVNode(" " + _toDisplayString(carrier.groupName), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_ion_note, { slot: "end" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(carrier.shipmentMethodCount) + " " + _toDisplayString(_ctx.translate('methods')), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["onClick"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("p", null, _toDisplayString(_ctx.translate("No carrier found.")), 1)
              ]))
        ]),
        _: 1
      }),
      _createVNode(_component_ion_fab, {
        vertical: "bottom",
        horizontal: "end",
        slot: "fixed"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_fab_button, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.createCarrier()))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, { icon: _ctx.addOutline }, null, 8, ["icon"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}