import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "filters" }
const _hoisted_3 = { class: "results" }
const _hoisted_4 = { class: "order-header" }
const _hoisted_5 = { class: "order-primary-info" }
const _hoisted_6 = { class: "order-tags" }
const _hoisted_7 = { class: "order-metadata" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "order-item" }
const _hoisted_11 = { class: "product-info" }
const _hoisted_12 = { class: "overline" }
const _hoisted_13 = { class: "product-metadata" }
const _hoisted_14 = {
  key: 0,
  class: "kit-components"
}
const _hoisted_15 = {
  key: 1,
  class: "kit-components"
}
const _hoisted_16 = { class: "overline" }
const _hoisted_17 = { class: "mobile-only" }
const _hoisted_18 = { class: "actions" }
const _hoisted_19 = { class: "desktop-only" }
const _hoisted_20 = { class: "desktop-only" }
const _hoisted_21 = {
  key: 2,
  class: "empty-state"
}
const _hoisted_22 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ViewSizeSelector = _resolveComponent("ViewSizeSelector")!
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_DxpShopifyImg = _resolveComponent("DxpShopifyImg")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ViewSizeSelector, {
        "menu-id": "view-size-selector-completed",
        "content-id": "view-size-selector"
      }),
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_menu_button, { slot: "start" }),
              (!_ctx.completedOrders.total)
                ? (_openBlock(), _createBlock(_component_ion_title, { key: 0 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.completedOrders.total) + " " + _toDisplayString(_ctx.translate('orders')), 1)
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_ion_title, { key: 1 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.completedOrders.query.viewSize) + " " + _toDisplayString(_ctx.translate('of')) + " " + _toDisplayString(_ctx.completedOrders.total) + " " + _toDisplayString(_ctx.completedOrders.total ? _ctx.translate('order') : _ctx.translate('orders')), 1)
                    ]),
                    _: 1
                  })),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button, {
                    menu: "view-size-selector-completed",
                    disabled: !_ctx.completedOrders.total
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.optionsOutline }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, {
        ref: "contentRef",
        "scroll-events": true,
        onIonScroll: _cache[4] || (_cache[4] = ($event: any) => (_ctx.enableScrolling())),
        id: "view-size-selector"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_searchbar, {
            class: "searchbar",
            value: _ctx.completedOrders.query.queryString,
            placeholder: _ctx.translate('Search orders'),
            onKeyup: _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.updateQueryString($event.target.value)), ["enter"]))
          }, null, 8, ["value", "placeholder"]),
          (_ctx.completedOrders.total)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.carrierPartyIds, (carrierPartyId) => {
                    return (_openBlock(), _createBlock(_component_ion_item, {
                      lines: "none",
                      key: carrierPartyId.val
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_checkbox, {
                          "label-placement": "end",
                          checked: _ctx.completedOrders.query.selectedCarrierPartyIds.includes(carrierPartyId.val),
                          onIonChange: ($event: any) => (_ctx.updateSelectedCarrierPartyIds(carrierPartyId.val))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.getPartyName(carrierPartyId.val.split('/')[0])) + " ", 1),
                                _createElementVNode("p", null, _toDisplayString(carrierPartyId.groups) + " " + _toDisplayString(carrierPartyId.groups === 1 ? _ctx.translate('package') : _ctx.translate("packages")), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["checked", "onIonChange"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shipmentMethods, (shipmentMethod) => {
                    return (_openBlock(), _createBlock(_component_ion_item, {
                      lines: "none",
                      key: shipmentMethod.val
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_checkbox, {
                          "label-placement": "end",
                          checked: _ctx.completedOrders.query.selectedShipmentMethods.includes(shipmentMethod.val),
                          onIonChange: ($event: any) => (_ctx.updateSelectedShipmentMethods(shipmentMethod.val))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.getShipmentMethodDesc(shipmentMethod.val)) + " ", 1),
                                _createElementVNode("p", null, _toDisplayString(shipmentMethod.groups) + " " + _toDisplayString(shipmentMethod.groups > 1 ? _ctx.translate('orders') : _ctx.translate('order')) + ", " + _toDisplayString(shipmentMethod.itemCount) + " " + _toDisplayString(shipmentMethod.itemCount > 1 ? _ctx.translate('items') : _ctx.translate('item')), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["checked", "onIonChange"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_ion_button, {
                    disabled: _ctx.isShipNowDisabled || !_ctx.hasAnyPackedShipment() || _ctx.hasAnyMissingInfo() || (_ctx.hasAnyShipmentTrackingInfoMissing() && !_ctx.hasPermission(_ctx.Actions.APP_FORCE_SHIP_ORDER)),
                    expand: "block",
                    class: "bulk-action desktop-only",
                    fill: "outline",
                    size: "large",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.bulkShipOrders()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.translate("Ship")), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled"]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getCompletedOrders(), (order, index) => {
                    return (_openBlock(), _createBlock(_component_ion_card, {
                      class: "order",
                      key: index
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("div", _hoisted_5, [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createElementVNode("strong", null, _toDisplayString(order.customerName), 1),
                                _createElementVNode("p", null, _toDisplayString(_ctx.translate("Ordered")) + " " + _toDisplayString(_ctx.formatUtcDate(order.orderDate, 'dd MMMM yyyy t a ZZZZ')), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _createElementVNode("div", _hoisted_6, [
                            _createVNode(_component_ion_chip, {
                              onClick: _withModifiers(($event: any) => (_ctx.orderActionsPopover(order, $event)), ["stop"]),
                              outline: ""
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: _ctx.pricetagOutline }, null, 8, ["icon"]),
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(order.orderName), 1)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createVNode(_component_ion_icon, { icon: _ctx.caretDownOutline }, null, 8, ["icon"])
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _createElementVNode("div", _hoisted_7, [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(order.shipmentMethodTypeDesc) + " ", 1),
                                (order.reservedDatetime)
                                  ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.translate("Last brokered")) + " " + _toDisplayString(_ctx.formatUtcDate(order.reservedDatetime, 'dd MMMM yyyy t a ZZZZ')), 1))
                                  : _createCommentVNode("", true),
                                (order.trackingCode)
                                  ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.translate("Tracking Code")) + " " + _toDisplayString(order.trackingCode), 1))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024)
                          ])
                        ]),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(order.items, (item) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: item.orderItemSeqId,
                            class: "order-line-item"
                          }, [
                            _createElementVNode("div", _hoisted_10, [
                              _createElementVNode("div", _hoisted_11, [
                                _createVNode(_component_ion_item, { lines: "none" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_DxpShopifyImg, {
                                          src: _ctx.getProduct(item.productId).mainImageUrl,
                                          size: "small"
                                        }, null, 8, ["src"])
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => [
                                        _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.secondaryId, _ctx.getProduct(item.productId))), 1),
                                        _createTextVNode(" " + _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) ? _ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) : _ctx.getProduct(item.productId).productName) + " ", 1),
                                        (_ctx.isKit(item))
                                          ? (_openBlock(), _createBlock(_component_ion_badge, {
                                              key: 0,
                                              color: "dark"
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.translate("Kit")), 1)
                                              ]),
                                              _: 1
                                            }))
                                          : _createCommentVNode("", true),
                                        _createElementVNode("p", null, _toDisplayString(_ctx.getFeature(_ctx.getProduct(item.productId).featureHierarchy, '1/COLOR/')) + " " + _toDisplayString(_ctx.getFeature(_ctx.getProduct(item.productId).featureHierarchy, '1/SIZE/')), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _createElementVNode("div", _hoisted_13, [
                                (_ctx.isKit(item))
                                  ? (_openBlock(), _createBlock(_component_ion_button, {
                                      key: 0,
                                      fill: "clear",
                                      size: "small",
                                      onClick: _withModifiers(($event: any) => (_ctx.fetchKitComponents(item)), ["stop"])
                                    }, {
                                      default: _withCtx(() => [
                                        (item.showKitComponents)
                                          ? (_openBlock(), _createBlock(_component_ion_icon, {
                                              key: 0,
                                              color: "medium",
                                              slot: "icon-only",
                                              icon: _ctx.chevronUpOutline
                                            }, null, 8, ["icon"]))
                                          : (_openBlock(), _createBlock(_component_ion_icon, {
                                              key: 1,
                                              color: "medium",
                                              slot: "icon-only",
                                              icon: _ctx.listOutline
                                            }, null, 8, ["icon"]))
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]))
                                  : _createCommentVNode("", true),
                                (item.productTypeId === 'GIFT_CARD')
                                  ? (_openBlock(), _createBlock(_component_ion_button, {
                                      key: 1,
                                      color: "medium",
                                      fill: "clear",
                                      size: "small",
                                      onClick: ($event: any) => (_ctx.openGiftCardActivationModal(item))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_icon, {
                                          slot: "icon-only",
                                          icon: item.isGCActivated ? _ctx.gift : _ctx.giftOutline
                                        }, null, 8, ["icon"])
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]))
                                  : _createCommentVNode("", true),
                                (_ctx.getProductStock(item.productId).quantityOnHandTotal)
                                  ? (_openBlock(), _createBlock(_component_ion_note, { key: 2 }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.getProductStock(item.productId).quantityOnHandTotal) + " " + _toDisplayString(_ctx.translate('pieces in stock')), 1)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : (_openBlock(), _createBlock(_component_ion_button, {
                                      key: 3,
                                      fill: "clear",
                                      size: "small",
                                      onClick: _withModifiers(($event: any) => (_ctx.fetchProductStock(item.productId)), ["stop"])
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_icon, {
                                          color: "medium",
                                          slot: "icon-only",
                                          icon: _ctx.cubeOutline
                                        }, null, 8, ["icon"])
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]))
                              ])
                            ]),
                            (item.showKitComponents && !_ctx.getProduct(item.productId)?.productComponents)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                  _createVNode(_component_ion_item, { lines: "none" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_skeleton_text, {
                                        animated: "",
                                        style: {"height":"80%"}
                                      })
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_item, { lines: "none" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_skeleton_text, {
                                        animated: "",
                                        style: {"height":"80%"}
                                      })
                                    ]),
                                    _: 1
                                  })
                                ]))
                              : (item.showKitComponents && _ctx.getProduct(item.productId)?.productComponents)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getProduct(item.productId).productComponents, (productComponent, index) => {
                                      return (_openBlock(), _createBlock(_component_ion_card, { key: index }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_item, { lines: "none" }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_DxpShopifyImg, {
                                                    src: _ctx.getProduct(productComponent.productIdTo).mainImageUrl,
                                                    size: "small"
                                                  }, null, 8, ["src"])
                                                ]),
                                                _: 2
                                              }, 1024),
                                              _createVNode(_component_ion_label, null, {
                                                default: _withCtx(() => [
                                                  _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.secondaryId, _ctx.getProduct(productComponent.productIdTo))), 1),
                                                  _createTextVNode(" " + _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(productComponent.productIdTo)) ? _ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(productComponent.productIdTo)) : productComponent.productIdTo) + " ", 1),
                                                  _createElementVNode("p", null, _toDisplayString(_ctx.getFeature(_ctx.getProduct(productComponent.productIdTo).featureHierarchy, '1/COLOR/')) + " " + _toDisplayString(_ctx.getFeature(_ctx.getProduct(productComponent.productIdTo).featureHierarchy, '1/SIZE/')), 1)
                                                ]),
                                                _: 2
                                              }, 1024)
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]),
                                        _: 2
                                      }, 1024))
                                    }), 128))
                                  ]))
                                : _createCommentVNode("", true)
                          ]))
                        }), 128)),
                        _createElementVNode("div", _hoisted_17, [
                          _createVNode(_component_ion_item, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_button, {
                                disabled: _ctx.isShipNowDisabled || order.hasMissingShipmentInfo || order.hasMissingPackageInfo || ((_ctx.isTrackingRequiredForAnyShipmentPackage(order) && !order.trackingCode) && !_ctx.hasPermission(_ctx.Actions.APP_FORCE_SHIP_ORDER)),
                                fill: "clear"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.translate("Ship Now")), 1)
                                ]),
                                _: 2
                              }, 1032, ["disabled"]),
                              _createVNode(_component_ion_button, {
                                slot: "end",
                                fill: "clear",
                                color: "medium",
                                onClick: _withModifiers(_ctx.shippingPopover, ["stop"])
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    slot: "icon-only",
                                    icon: _ctx.ellipsisVerticalOutline
                                  }, null, 8, ["icon"])
                                ]),
                                _: 1
                              }, 8, ["onClick"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _createElementVNode("div", _hoisted_18, [
                          _createElementVNode("div", _hoisted_19, [
                            (!_ctx.hasPackedShipments(order))
                              ? (_openBlock(), _createBlock(_component_ion_button, {
                                  key: 0,
                                  disabled: true
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("Shipped")), 1)
                                  ]),
                                  _: 1
                                }))
                              : (_openBlock(), _createBlock(_component_ion_button, {
                                  key: 1,
                                  disabled: _ctx.isShipNowDisabled || order.hasMissingShipmentInfo || order.hasMissingPackageInfo || ((_ctx.isTrackingRequiredForAnyShipmentPackage(order) && !order.trackingCode) && !_ctx.hasPermission(_ctx.Actions.APP_FORCE_SHIP_ORDER)),
                                  onClick: _withModifiers(($event: any) => (_ctx.shipOrder(order)), ["stop"])
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("Ship Now")), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["disabled", "onClick"])),
                            _createVNode(_component_ion_button, {
                              disabled: order.hasMissingShipmentInfo || order.hasMissingPackageInfo,
                              fill: "outline",
                              onClick: _withModifiers(($event: any) => (_ctx.regenerateShippingLabel(order)), ["stop"])
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("Regenerate Shipping Label")) + " ", 1),
                                (order.isGeneratingShippingLabel)
                                  ? (_openBlock(), _createBlock(_component_ion_spinner, {
                                      key: 0,
                                      color: "primary",
                                      slot: "end",
                                      name: "crescent"
                                    }))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1032, ["disabled", "onClick"]),
                            _createVNode(_component_ion_button, {
                              disabled: order.hasMissingShipmentInfo || order.hasMissingPackageInfo,
                              fill: "outline",
                              onClick: _withModifiers(($event: any) => (_ctx.printPackingSlip(order)), ["stop"])
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("Print Customer Letter")) + " ", 1),
                                (order.isGeneratingPackingSlip)
                                  ? (_openBlock(), _createBlock(_component_ion_spinner, {
                                      key: 0,
                                      color: "primary",
                                      slot: "end",
                                      name: "crescent"
                                    }))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1032, ["disabled", "onClick"])
                          ]),
                          _createElementVNode("div", _hoisted_20, [
                            (order.missingLabelImage)
                              ? (_openBlock(), _createBlock(_component_ion_button, {
                                  key: 0,
                                  fill: "outline",
                                  onClick: _withModifiers(($event: any) => (_ctx.showShippingLabelErrorModal(order)), ["stop"])
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("Shipping label error")), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]))
                              : _createCommentVNode("", true),
                            _createVNode(_component_ion_button, {
                              disabled: _ctx.isUnpackDisabled || !_ctx.hasPermission(_ctx.Actions.APP_UNPACK_ORDER) || order.hasMissingShipmentInfo || order.hasMissingPackageInfo || !_ctx.hasPackedShipments(order),
                              fill: "outline",
                              color: "danger",
                              onClick: _withModifiers(($event: any) => (_ctx.unpackOrder(order)), ["stop"])
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("Unpack")), 1)
                              ]),
                              _: 2
                            }, 1032, ["disabled", "onClick"])
                          ])
                        ])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128)),
                  _withDirectives(_createVNode(_component_ion_infinite_scroll, {
                    onIonInfinite: _cache[2] || (_cache[2] = ($event: any) => (_ctx.loadMoreCompletedOrders($event))),
                    threshold: "100px",
                    ref: "infiniteScrollRef"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_infinite_scroll_content, {
                        "loading-spinner": "crescent",
                        "loading-text": _ctx.translate('Loading')
                      }, null, 8, ["loading-text"])
                    ]),
                    _: 1
                  }, 512), [
                    [_vShow, _ctx.isCompletedOrderScrollable()]
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.completedOrders.total)
            ? (_openBlock(), _createBlock(_component_ion_fab, {
                key: 1,
                class: "mobile-only",
                vertical: "bottom",
                horizontal: "end",
                slot: "fixed"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_fab_button, {
                    disabled: !_ctx.hasAnyPackedShipment() || _ctx.hasAnyMissingInfo() || (_ctx.hasAnyShipmentTrackingInfoMissing() && !_ctx.hasPermission(_ctx.Actions.APP_FORCE_SHIP_ORDER)),
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.bulkShipOrders()))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.checkmarkDoneOutline }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                _: 1
              }))
            : (_openBlock(), _createElementBlock("div", _hoisted_21, [
                _createElementVNode("p", {
                  innerHTML: _ctx.getErrorMessage()
                }, null, 8, _hoisted_22)
              ]))
        ]),
        _: 1
      }, 512)
    ]),
    _: 1
  }))
}