import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_datetime_button = _resolveComponent("ion-datetime-button")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_menu = _resolveComponent("ion-menu")!

  return (_openBlock(), _createBlock(_component_ion_menu, {
    type: "overlay",
    side: "end",
    "content-id": "orderLookup-filter"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Filters")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_list_header, null, {
                default: _withCtx(() => [
                  _createElementVNode("h3", null, _toDisplayString(_ctx.translate("Brand")), 1)
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productStoreOptions, (productStore) => {
                return (_openBlock(), _createBlock(_component_ion_item, { key: productStore }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_checkbox, {
                      checked: _ctx.isProductStoreSelected(productStore),
                      onIonChange: ($event: any) => (_ctx.updateAppliedFilters($event['detail'].checked, 'productStore', productStore))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(productStore), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["checked", "onIonChange"])
                  ]),
                  _: 2
                }, 1024))
              }), 128)),
              (!_ctx.productStoreOptions.length)
                ? (_openBlock(), _createBlock(_component_ion_label, {
                    key: 0,
                    class: "ion-margin"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.translate("No brands found")), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_list, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_list_header, null, {
                default: _withCtx(() => [
                  _createElementVNode("h3", null, _toDisplayString(_ctx.translate("Type")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_checkbox, {
                    checked: _ctx.query.storePickup,
                    onIonChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateAppliedFilters($event['detail'].checked, 'storePickup')))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Store pickup")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["checked"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_checkbox, {
                    checked: _ctx.query.shipFromStore,
                    onIonChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateAppliedFilters($event['detail'].checked, 'shipFromStore')))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Ship from store")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["checked"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_list, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_list_header, null, {
                default: _withCtx(() => [
                  _createElementVNode("h3", null, _toDisplayString(_ctx.translate("Fulfillment")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_select, {
                    "selected-text": !_ctx.query.status.length ? _ctx.translate('All') : _ctx.query.status.length > 1 ? _ctx.query.status.length + _ctx.translate('items selected') : _ctx.query.status,
                    label: _ctx.translate('Status'),
                    disabled: !_ctx.orderStatusOptions.length,
                    multiple: true,
                    value: _ctx.query.status,
                    onIonChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateAppliedFilters($event['detail'].value, 'status'))),
                    interface: "popover"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderStatusOptions, (status) => {
                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                          key: status,
                          value: status
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate(status)), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["selected-text", "label", "disabled", "value"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_select, {
                    "selected-text": !_ctx.query.facility.length ? _ctx.translate('All') : _ctx.query.facility.length > 1 ? _ctx.query.facility.length + _ctx.translate('items selected') : _ctx.query.facility,
                    label: _ctx.translate('Facility'),
                    disabled: !_ctx.facilityOptions.length,
                    multiple: true,
                    value: _ctx.query.facility,
                    onIonChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateAppliedFilters($event['detail'].value, 'facility'))),
                    interface: "popover"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.facilityOptions, (facility) => {
                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                          key: facility,
                          value: facility
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(facility), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["selected-text", "label", "disabled", "value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_list, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_list_header, null, {
                default: _withCtx(() => [
                  _createElementVNode("h3", null, _toDisplayString(_ctx.translate("Channel")), 1)
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.channelOptions, (channel) => {
                return (_openBlock(), _createBlock(_component_ion_item, { key: channel }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_checkbox, {
                      checked: _ctx.isChannelSelected(channel),
                      onIonChange: ($event: any) => (_ctx.updateAppliedFilters($event['detail'].checked, 'channel', channel))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate(channel)), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["checked", "onIonChange"])
                  ]),
                  _: 2
                }, 1024))
              }), 128)),
              (!_ctx.channelOptions.length)
                ? (_openBlock(), _createBlock(_component_ion_label, {
                    key: 0,
                    class: "ion-margin"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.translate("No channels found")), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_list, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_list_header, null, {
                default: _withCtx(() => [
                  _createElementVNode("h3", null, _toDisplayString(_ctx.translate("Date")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_select, {
                    label: _ctx.translate('Date range'),
                    value: _ctx.query.date,
                    onIonChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateAppliedFilters($event['detail'].value, 'date'))),
                    interface: "popover"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dateRanges, (range) => {
                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                          key: range.label,
                          value: range.value
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.translate(range.label)), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["label", "value"])
                ]),
                _: 1
              }),
              (_ctx.query.date === 'custom')
                ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("From")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_datetime_button, {
                        datetime: "fromDate",
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.enableFromDateFilter()))
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _withDirectives(_createVNode(_component_ion_datetime, {
                onIonChange: _cache[6] || (_cache[6] = ($event: any) => (_ctx.updateAppliedFilters($event['detail'].value, 'fromDate'))),
                id: "fromDate",
                presentation: "date",
                modelValue: _ctx.fromDate,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.fromDate) = $event)),
                max: _ctx.toDate || _ctx.DateTime.now().toISO()
              }, null, 8, ["modelValue", "max"]), [
                [_vShow, _ctx.enableFromDate]
              ]),
              (_ctx.query.date === 'custom')
                ? (_openBlock(), _createBlock(_component_ion_item, { key: 1 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("To")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_datetime_button, {
                        datetime: "toDate",
                        onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.enableToDateFilter()))
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _withDirectives(_createVNode(_component_ion_datetime, {
                onIonChange: _cache[9] || (_cache[9] = ($event: any) => (_ctx.updateAppliedFilters($event['detail'].value, 'toDate'))),
                id: "toDate",
                presentation: "date",
                modelValue: _ctx.toDate,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.toDate) = $event)),
                min: _ctx.fromDate,
                max: _ctx.DateTime.now().toISO()
              }, null, 8, ["modelValue", "min", "max"]), [
                [_vShow, _ctx.enableToDate]
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}