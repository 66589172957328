import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8b4855be"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "empty-state"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 2,
  class: "desktop-only"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_MappingConfiguration = _resolveComponent("MappingConfiguration")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_back_button, {
                slot: "start",
                "default-href": "/exim"
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Saved mappings")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("main", null, [
            (!_ctx.areFieldMappingsAvailable)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.translate("There are no saved CSV mappings to show. Create a new mapping from a file upload screen")), 1)
                ]))
              : (_openBlock(), _createElementBlock("section", _hoisted_2, [
                  (Object.keys(_ctx.fieldMappings('IMPORD')).length)
                    ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_list_header, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("Import Orders")), 1)
                            ]),
                            _: 1
                          }),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fieldMappings('IMPORD'), (mapping, index) => {
                            return (_openBlock(), _createBlock(_component_ion_item, {
                              key: index,
                              onClick: ($event: any) => (_ctx.viewMappingConfiguration(index, 'IMPORD')),
                              detail: "",
                              button: ""
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(mapping.name), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          }), 128))
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (Object.keys(_ctx.fieldMappings('EXPORD')).length)
                    ? (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_list_header, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("Export Orders")), 1)
                            ]),
                            _: 1
                          }),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fieldMappings('EXPORD'), (mapping, index) => {
                            return (_openBlock(), _createBlock(_component_ion_item, {
                              key: index,
                              onClick: ($event: any) => (_ctx.viewMappingConfiguration(index, 'EXPORD')),
                              detail: "",
                              button: ""
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(mapping.name), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          }), 128))
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ])),
            (_ctx.isDesktop)
              ? _withDirectives((_openBlock(), _createElementBlock("aside", _hoisted_3, [
                  _createVNode(_component_MappingConfiguration)
                ], 512)), [
                  [_vShow, _ctx.currentMapping.id != '']
                ])
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}