import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createBlock(_component_ion_content, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_list, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list_header, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.shipmentMethod.description ? _ctx.shipmentMethod.description : _ctx.shipmentMethod.shipmentMethodTypeId), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, {
            lines: "none",
            button: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.renameShipmentMethod()))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                slot: "end",
                icon: _ctx.pencilOutline
              }, null, 8, ["icon"]),
              _createTextVNode(" " + _toDisplayString(_ctx.translate("Edit name")), 1)
            ]),
            _: 1
          }),
          (_ctx.shipmentMethod.isChecked)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_ion_item, {
                  button: "",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.editDeliveryDays()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "end",
                      icon: _ctx.calendarClearOutline
                    }, null, 8, ["icon"]),
                    _createTextVNode(" " + _toDisplayString(_ctx.translate("Edit delivery days")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_item, {
                  button: "",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.editCarrierCode()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "end",
                      icon: _ctx.codeWorkingOutline
                    }, null, 8, ["icon"]),
                    _createTextVNode(" " + _toDisplayString(_ctx.translate("Edit carrier code")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_item, {
                  button: "",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openEditSequenceModal()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "end",
                      icon: _ctx.listOutline
                    }, null, 8, ["icon"]),
                    _createTextVNode(" " + _toDisplayString(_ctx.translate("Edit sequence")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_item, {
                  lines: "none",
                  button: "",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.removeCarrierShipmentMethod()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "end",
                      icon: _ctx.unlinkOutline
                    }, null, 8, ["icon"]),
                    _createTextVNode(" " + _toDisplayString(_ctx.translate("Remove from carrier")), 1)
                  ]),
                  _: 1
                })
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}